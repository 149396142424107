<template>
  <main>
    <!-- 背景1 -->
    <div class="bg1">
      <img v-lazy="require('./zhjd_img/bg1.webp')" alt="" class="banner" />
      <span class="zhjd">大数据分析决策</span>
      <span class="zhfw"
        >对接酒店企业的各种数据源，进行多维度的即席分析，多个维度分析 <br />
        经营状况，满足不同用户的需求，同时提升整体系统的运作效率。</span
      >
    </div>
    <!-- 背景1 -->
    <!-- 背景2 -->
    <div class="bg2">
      <span class="sjfx">数据分析</span>
      <span class="fh1"
        >针对交易、采购、员工管理等业务活动，提供各看板、报表、预测、统计分析及建议等产品能力</span
      >
      <div class="img1">
        <div class="img11">
          <img v-lazy="require('./sjfx_img/image1.webp')" class="image1" />
        </div>
        <span class="jygl">交易管理看板</span>
      </div>
      <div class="img2">
        <div class="img22">
          <img v-lazy="require('./sjfx_img/image2.webp')" class="image2" />
        </div>
        <span class="cgfx">采购分析图表</span>
      </div>
      <div class="img3">
        <div class="img33">
          <img v-lazy="require('./sjfx_img/image3.webp')" class="image3" />
        </div>
        <span class="fjtj">房价/溢价统计</span>
      </div>
      <div class="img4">
        <div class="img44">
          <img v-lazy="require('./sjfx_img/image4.webp')" class="image4" />
        </div>
        <span class="zxcz">在线操作员统计</span>
      </div>
      <div class="img5">
        <div class="img55">
          <img v-lazy="require('./sjfx_img/image5.webp')" class="image5" />
        </div>
      </div>
    </div>
    <!-- 背景2 -->
    <!-- 背景3 -->
    <div class="bg3">
      <span class="fh2">经营分析平台同时支持移动端使用</span>
      <div class="img66">
        <img v-lazy="require('./sjfx_img/image6.webp')" class="image6" />
      </div>
      <div class="img77">
        <img v-lazy="require('./sjfx_img/image7.webp')" class="image7" />
      </div>
      <div class="img88">
      <img v-lazy="require('./sjfx_img/image8.webp')" class="image8" />
      </div>
      <div class="img99">
      <img v-lazy="require('./sjfx_img/image9.webp')" class="image9" /></div>
    </div>
    <!-- 背景3 -->
    <!-- 背景4 -->
    <div class="bg4">
      <span class="yhhx">用户画像体系</span>
      <span class="fxjg"
        >通过腾讯生态下海量用户数据，提供基于LBS位置大数据的用户画像及空间洞察分析结果</span
      >
      <div class="icon11">
      <img v-lazy="require('./sjfx_img/icon1.webp')" class="icon1" />
      </div>
      <!-- <img src="./sjfx_img/image10.png" alt="" class="image10" /> -->
    </div>
    <!-- 背景4 -->
    <Header></Header>
    <Footer></Footer>
  </main>
</template>

<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.bg1 {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
  .banner {
    height: 600px;
  }
  .zhjd {
    min-width: 206px;
    height: 67px;
    font-size: 48px;
    color: #ffffff;
    position: absolute;
    top: 230px;
    left: 361px;
  }
  .zhfw {
    min-width: 670px;
    height: 66px;
    font-size: 24px;
    color: #ffffff;
    position: absolute;
    top: 321px;
    left: 361px;
    text-align: left;
  }
}
.bg2 {
  height: 1613px;
  position: relative;
  .sjfx {
    min-width: 165px;
    height: 56px;
    font-size: 40px;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 876px;
  }
  .fh1 {
    min-width: 882px;
    height: 40px;
    font-size: 20px;
    color: #565656;
    position: absolute;
    top: 160px;
    left: 519px;
  }
  .img1 {
    width: 584px;
    height: 380px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 260px;
    left: 360px;
    .img11 {
      width: 584px;
      height: 285px;
      position: absolute;
      top: 18px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img[lazy="loading"] {
        width: 200px;
        height: 200px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        height: 340px;
        width: 100%;
      }
      .image1 {
        width: 584px;
      }
    }
    .jygl {
      min-width: 125px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 328px;
      left: 214px;
    }
  }
  .img2 {
    min-width: 584px;
    height: 380px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 260px;
    left: 974px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 320px;
      width: 100%;
    }
    .img22 {
      min-width: 560px;
      height: 320px;
      position: absolute;
      top: 0px;
      left: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img[lazy="loading"] {
        width: 200px;
        height: 200px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        height: 320px;
        width: 100%;
      }
      .image2 {
        min-width: 560px;
      }
    }
    .cgfx {
      min-width: 125px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 328px;
      left: 214px;
    }
  }
  .img3 {
    min-width: 787px;
    height: 388px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 670px;
    left: 360px;

    .img33 {
      width: 787px;
      height: 257px;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img[lazy="loading"] {
        width: 200px;
        height: 200px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        height: 257px;
        width: 100%;
      }
      .image3 {
        width: 787px;
      }
    }
    .fjtj {
      width: 136px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 336px;
      left: 326px;
    }
  }
  .img4 {
    min-width: 381px;
    height: 388px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 670px;
    left: 1178px;
    .img44 {
      width: 381px;
      height: 320px;
      position: absolute;
      top: 0;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      img[lazy="loading"] {
        width: 200px;
        height: 200px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        height: 350px;
        width: 100%;
      }
      .image4 {
        width: 350px;
      }
    }
    .zxcz {
      min-width: 146px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 336px;
      left: 118px;
    }
  }
  .img5 {
    min-width: 1198px;
    height: 355px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 1088px;
    left: 360px;
    .img55 {
      width: 1098px;
      height: 341px;
      position: absolute;
      top: 14px;
      left: 51px;
      display: flex;
      align-items: center;
      justify-content: center;
      img[lazy="loading"] {
        width: 200px;
        height: 200px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        height: 355px;
        width: 100%;
      }
      .image5 {
        width: 1098px;
      }
    }
  }
}
.bg3 {
  height: 670px;
  background: #f6f8fb;
  position: relative;
  .fh2 {
    min-width: 619px;
    height: 56px;
    font-size: 40px;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 650px;
  }
  .img66 {
    width: 279px;
    height: 384px;
    position: absolute;
    top: 191px;
    left: 359px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 384px;
      width: 100%;
    }
    .image6 {
      width: 279px;
    }
  }
  .img77 {
    width: 279px;
    height: 384px;
    position: absolute;
    top: 191px;
    left: 667px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 384px;
      width: 100%;
    }
    .image7 {
      width: 279px;
    }
  }
  .img88{
    width: 279px;
    height: 384px;
    position: absolute;
    top: 191px;
    left: 975px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 384px;
      width: 100%;
    }
  .image8 {
     width: 279px;
  }}
  .img99{
   width: 279px;
    height: 384px;
    position: absolute;
    top: 191px;
    left: 1283px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 384px;
      width: 100%;
    }
  .image9 {
    width: 279px;

  }}
}
.bg4 {
  height: 943px;
  position: relative;
  .yhhx {
    min-width: 248px;
    height: 56px;
    font-size: 40px;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 836px;
  }
  .fxjg {
    min-width: 818px;
    height: 40px;
    font-size: 20px;
    color: #565656;
    position: absolute;
    top: 160px;
    left: 551px;
  }
  .icon11{
    width: 747px;
    height: 435px;
    position: absolute;
    top: 260px;
    left: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 435px;
      width: 100%;
    }
  .icon1 {
    width: 747px;
  }}
  .image10 {
    min-width: 502px;
    height: 47px;
    position: absolute;
    top: 796px;
    left: 711px;
  }
}
</style>
